.Command {
	position: fixed;
	top: 20%;
	left: 50%;
	transform: translateX(-50%);
	background-color: var(--color-white);
	border-radius: var(--border-radius-md);
	border: 1px solid var(--color-grey-3);
	width: 60rem;
	padding: 0.5rem;
	box-shadow: 0 16px 70px rgb(0 0 0 / 20%);
	z-index: var(--command-palette-z);
}

.Input {
	border: none;
	border-radius: 0;
	background-color: var(--color-white);
}

.Input:hover {
	border: none;
}

.Input:focus {
	border: none;
}

.InputContainer {
	border-bottom: 1px solid var(--color-grey-3);
	padding-bottom: 0.5rem;
}

.List {
	padding: 0.65rem 0.5rem;
	overflow-y: scroll;
	max-height: 30rem;
}

.List div[cmdk-group='']:not(:last-child) {
	margin-bottom: 1rem;
}

.Item {
	padding: 0.75rem 0.5rem;
	margin-top: 0.2rem;
	border-radius: var(--border-radius-md);
	color: var(--base-color-black);
	cursor: pointer;
}

.Item[data-selected='true'] {
	background-color: var(--color-violet-2);
}

.GroupHeading > div[cmdk-group-heading=''] {
	color: var(--color-grey-6);
	font-size: var(--scale-00);
}

.FilterList {
	margin-bottom: 1rem;
}

.FilterToggle {
	border-radius: var(--border-radius-md);
	border: 1px solid var(--color-grey-3);
	padding: 0.5rem;
	color: var(--color-grey-8);
}

.FilterToggled {
	background-color: var(--color-violet-2);
	border: 1px solid var(--color-violet-4);
}

.Label {
	background-color: var(--color-grey-2);
	border-radius: var(--border-radius-md);
	padding: 0.25rem;
}

.ShortcutHints {
	cursor: pointer;
	padding: 0.5rem;
	border-radius: var(--border-radius-md);
	background-color: var(--color-grey-2);
	font-size: var(--scale-0000);
	font-weight: var(--weight-semibold);
	color: var(--base-color-black);
}
