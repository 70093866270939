.RangeSlider {
	width: fit-content;
}

.HorizontalSlider {
	width: 100%;
	height: 1.5rem;
	display: flex;
	align-items: flex-end;
}

.SliderWithMarks {
	margin-bottom: 1rem;
}

.Track {
	bottom: 0.1rem;
	height: 0.6rem;
	border-radius: 4rem;
}

.Track0 {
	background: var(--color-grey-4);
}

.Track1 {
	background: var(--color-violet-6);
}

.Track2 {
	background: var(--color-grey-4);
}

.Thumb {
	color: var(--color-white);
	border-radius: 50%;
	box-shadow: 0 0 0.5rem var(--color-black);
	cursor: pointer;
}

.ThumbContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 1rem;
}

.ToggleBar {
	width: 100%;
	justify-content: space-between;
}

.SliderContainer {
	width: 100%;
	background: transparent;
	padding: var(--spacing-1) var(--spacing-5);
}

.Mark {
	color: var(--color-grey-7);
	background-color: var(--color-grey-2);
	bottom: -1.75rem;
	border-radius: var(--border-radius-sm);
	font-size: 0.75rem;
	padding: 0.125rem 0.25rem;
}

.MarkActive {
	color: var(--color-violet-7);
}

.Reset.Reset {
	color: var(--color-violet-7);
}
