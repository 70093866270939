@keyframes background-animation {
	0% {
		background-position: 200% 0;
	}

	100% {
		background-position: -100% 0;
	}
}

.SkeletonText {
	background-color: #eaeaea;
	background-image: linear-gradient(90deg, #eaeaea, #f7f7f7, #eaeaea);
	background-size: 200% 100%;
	background-repeat: no-repeat;
	border-radius: var(--border-radius-md);
	display: inline-block;
	width: 100%;
	animation: background-animation 5s ease-in-out infinite;
	margin-bottom: 0.25rem;
}

.WidthSmall {
	width: 5rem;
}

.WidthMedium {
	width: 10rem;
}

.WidthLarge {
	width: 15rem;
}
