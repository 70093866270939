.Filters {
	margin-bottom: var(--spacing-sm);
}

.Select {
	width: 100%;
}

.MinGradeInput {
	min-height: 2.5rem;
	border-radius: var(--form-field-border-radius);
	padding: var(--spacing-0) var(--spacing-1);
}

.CountryProvinceGrid {
	grid-column: 1 / 3;
}
