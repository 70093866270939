.Select {
	width: 15rem;
}

.Filters {
	margin-bottom: 1rem;
}

.DatePickerFilter {
	width: 15rem !important;
}

.DatePickerFilter input {
	border: 1px solid var(--base-color-light);
	border-radius: 0.25rem;
	padding: 0 0.5rem;
	background-color: var(--color-white);
	margin: 0;
	font-family: var(--font-family);
	color: var(--base-color-medium);
	transition: 0.3s;
	cursor: pointer;
}

.DatePickerFilter span {
	display: none;
}

.DatePickerFilter input:focus {
	border: 1px solid var(--base-color-light);
	border-radius: 0.25rem;
	padding: 0 0.5rem;
	background-color: var(--color-white);
	box-shadow: none;
}
