.RangeInput {
	padding: var(--spacing-1) var(--spacing-2);
	font-size: var(--font-size-sm);
	border: 1px solid transparent;
	background: transparent;
	width: auto;
}

.RangeLabel {
	padding: 0;
	margin: 0;
	color: var(--color-grey-7);
	white-space: nowrap;
}

.RangeUnitLabel {
	width: 1rem;
	padding: 0;
	margin: 0;
	color: var(--color-grey-7);
}

.RangeContainer {
	border: 1px solid var(--color-grey-4);
	border-radius: var(--border-radius-md);
	padding: 0.2rem var(--spacing-2);
	margin: 0 var(--spacing-1);
}
