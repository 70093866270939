/* stylelint-disable selector-pseudo-class-no-unknown */
.Animation {
	animation-duration: var(--animation-duration);
}

.AnimationFadeIn :global {
	animation-name: fade-in;
	animation-timing-function: var(--ease-out-quad);
}

.AnimationFadeOut :global {
	animation-name: fade-out;
	animation-timing-function: var(--ease-out-quad);
}

.AnimationSpin :global {
	animation-name: spin;
	animation-timing-function: linear;
}

.AnimationFloat :global {
	animation-name: float;
	animation-timing-function: var(--ease-in-quad);
}

.AnimationPulse :global {
	animation-name: pulse;
	animation-timing-function: var(--ease-in-out-quad);
}

.AnimationScaleOutAndIn :global {
	animation-name: scale-out-and-in;
	animation-timing-function: ease-in-out;
}

.AnimationShakeX :global {
	animation-name: shake-x;
	animation-timing-function: var(--ease-in-out-quart);
}

.AnimationShakeY :global {
	animation-name: shake-y;
	animation-timing-function: var(--ease-in-out-quart);
}

.AnimationDuration1 {
	animation-duration: calc(var(--animation-duration) * 0.25);
}

.AnimationDuration2 {
	animation-duration: calc(var(--animation-duration) * 0.5);
}

.AnimationDuration3 {
	animation-duration: calc(var(--animation-duration) * 0.75);
}

.AnimationDuration4 {
	animation-duration: calc(var(--animation-duration) * 1.25);
}

.AnimationDuration5 {
	animation-duration: calc(var(--animation-duration) * 2);
}

.AnimationDuration6 {
	animation-duration: calc(var(--animation-duration) * 3);
}

.AnimationDelay1 {
	animation-delay: calc(var(--animation-duration) * 0.5);
}

.AnimationDelay2 {
	animation-delay: calc(var(--animation-duration) * 0.75);
}

.AnimationDelay3 {
	animation-delay: calc(var(--animation-duration) * 1.25);
}

.AnimationDelay4 {
	animation-delay: calc(var(--animation-duration) * 2);
}

.AnimationDelay5 {
	animation-delay: calc(var(--animation-duration) * 3);
}

.AnimationInfinite {
	animation-iteration-count: infinite;
}

.AnimationDirectionReverse {
	animation-direction: reverse;
}

.AnimationDirectionAlternate {
	animation-direction: alternate;
}

.AnimationDirectionAlternateReverse {
	animation-direction: alternate-reverse;
}
