.NotificationBadge {
	right: 1px;
	background: var(--color-red);
	border-radius: 50%;
	width: 1.1rem;
	height: 1.1rem;
	font-size: 0.8rem;
	text-align: center;
	color: var(--color-white);
}

.NotificationBadgeRelative {
	position: relative;
}

.NotificationBadgeAbsolute {
	position: absolute;
}
