.FormField {
	margin-bottom: 1rem;
}

.FormField input {
	border: 1px solid var(--formfield-border-color);
	border-radius: var(--form-field-border-radius);
}

.FormField input:hover {
	border: 1px solid var(--formfield-hover-border-color);
	background-color: var(--formfield-hover-background-color);
	color: var(--formfield-hover-color);
}

.FormField input:focus {
	background-color: transparent;
	border: 1px solid var(--formfield-focus-border-color);
	color: var(--formfield-focus-color);
}

.FormFieldLabel {
	margin-bottom: 0.5rem;
	color: var(--color-grey-7);
}

.Checkbox {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
}

.Checkbox > *:first-child {
	white-space: nowrap;
}

.CurrencyInput {
	min-width: 7rem;
}

.Checkbox > *:last-child {
	width: 1.2rem;
	height: 1.2rem;
	margin-right: 0.5rem;
}

.FormDurationInput {
	width: 100%;
}
