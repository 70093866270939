.Chip {
	display: inline-block;
	padding: 0.5rem 1.25rem;
	border-radius: 1.125rem;
	text-decoration: none;
	transition: 0.3s;
	background-color: var(--base-color-light);
	border: 0;
}

.ChipPrimary {
	background-color: var(--primary-color-lighter);
	color: var(--primary-color-dark);
}

.ChipAccent {
	background-color: var(--accent-color-light);
	color: var(--accent-color-dark);
}

.Close {
	display: none;
}

.ChipToggled > .Close {
	display: inline-block;
	margin-left: 0.25rem;
}
