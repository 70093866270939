.ToggleBar {
	width: 100%;
	justify-content: space-between;
	line-height: 1.375rem;
}

.Dropdown {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid var(--color-grey-4);
	border-radius: var(--form-field-border-radius);
	padding: 0.45rem var(--spacing-1);
	background: white;
}

.Wrapper {
	min-width: 25rem;
	padding: var(--spacing-2) var(--spacing-1);
	border: 1px solid var(--color-grey-4);
	border-radius: var(--border-radius-md);
}
